import { useContext, useEffect } from "react";
import AlertContext from "../../Contexts/AlertContext";

const initialAlertState = {
    visible: false,
    body: "Hi, this is an alert",
    severity: "normal",
}
export default function Alert() {
    const { alertOptions, setAlertOptions } = useContext(AlertContext);
    const body = alertOptions?.body ?? "Hi, this is an alert";
    let severity = alertOptions?.severity ?? "normal";
    severity = severity.toLowerCase();
    let bgcolor, color,border,text;

    switch (severity) {
        case "success":
            bgcolor = "bg-green-100"
            color = 'text-green-700'
            border = ' border-green-700'
            text = 'Success'
            break;
        case "failure":
            bgcolor = "bg-red-100"
            color = 'text-red-700'
            border = ' border-red-700'
            text = 'Error'
            break;
        case "warning":
            bgcolor = "bg-yellow-100"
            color = 'text-yellow-700'
            border = ' border-yellow-700'
            text = "Warning"

            break;
        default:
            bgcolor = "bg-blue-100"
            color = 'text-blue-700'
            border = ' border-blue-700'
            text = "Normal"
    }

    useEffect(() => {
        setTimeout(() => setAlertOptions(initialAlertState), 4000);
    }, [alertOptions.visible]);


    return (

        (alertOptions?.visible ?? false) &&
        <div role="alert" className={`fixed z-50 top-4 right-4 shadow-md rounded py-2 px-6 mb-4 text-base border-l-4 ${color} ${bgcolor} ${border}`
        }>
            <p className="font-semibold">{text}</p>
            <p>{body}</p>
        </div>
    )
}