export default async function makeRequest(method, url, headers, body = '') {
    let fetchOptions = {
        method,
        credentials: "include",
        headers
    }
    switch (method.toUpperCase()) {
        case "POST":
        case "PUT":
        case "PATCH":
        case "DELETE":
            fetchOptions.body = JSON.stringify(body);
            break;
        default:
            break;
    }
    try {
        const response = await fetch(url, fetchOptions);
        const result = await response.json();
        return { response, result };

    } catch (err) {
        const response = false;
        const result = err;
        return { response, result }
    }
}