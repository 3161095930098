import Navbar from '../Navbar/Navbar'
import Alert from '../Alert/Alert';
import { AlertProvider } from '../../Contexts/AlertContext';
import { Outlet } from "react-router-dom";
import  DataContext from '../../Contexts/DataContext';
import FullScreenLoading from '../misc/FullScreenLoading/FullScreenLoading';
export default function Layout() {
    const { isLoading } = DataContext();
    return (
            <AlertProvider>
                <div className='flex flex-col'>
                    <Alert key="alert" />
                    <Navbar />
                    <div className="pt-20 min-h-screen flex flex-col gap-4">
                        <Outlet />
                    </div>
                </div>
                {isLoading && <FullScreenLoading />}
            </AlertProvider>



    )
}