import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AlertContext from "../../../Contexts/AlertContext";
import DataContext from "../../../Contexts/DataContext";
import userReq from "../../../network/userRequest";
import locations from "../../../data/locations";

//input fileds
import DateBlock from "./DateBlock/DateBlock";
import InputBlock from "./InputBlock/InputBlock";
import SelectBlock from "./SelectBlock/SelectBlock";
import MultiSelectBlock from "./MultiSelectBlock/MultiSelectBlock";
import RadioBlock from "./RadioBlock/RadioBlock";

import Button from "./Button/Button";

const labelStyle = "block capitalize tracking-wide text-gray-700 text-sm font-semibold mb-2";
const fieldStyle = "appearance-none block w-full text-gray-700 border border-gray-300 focus:bg-white rounded py-3 px-4 leading-tight focus:outline-none bg-white focus:border-blue-600";


export default function SignUp() {
    const navigate = useNavigate();
    const { setGlobalUser,setIsLoading } = DataContext();
    const { setAlertOptions, confirmModal } = useContext(AlertContext);
    const initialErrorsState = { visible: false, msgs: [] };
    const [errors, setErrors] = useState(initialErrorsState);


    const initialFormState = {
        fName: "",
        lName: "",
        dob: null,
        phone: "",
        waPhone: "",
        email: "",
        gender: "",
        address: "",
        inJaipur: "",
        location: null,
        moveJaipur: "",
        ownVehicle: "",
        intercity: "",
        overTime: "",
        maxEducation: null,
        workLocations: [], //multi
        experience: null,
        industries: [], //multi
        joinTime: null,

    }

    const [formState, setFormState] = useState(initialFormState);

    const [sameNumber, setSameNumber] = useState(false);

    function onChange(event) {
        setFormState(oldFormState => {
            if (formState[event.target.name] !== undefined) {
                return { ...oldFormState, [event.target.name]: event.target.value }

            }
            return oldFormState;
        }
        )
    }

    async function submitForm(event) {
        setIsLoading(true);
        event?.preventDefault();
        const keys = Object.keys(initialFormState);
        const requestBody = {};
        keys.forEach(key => {
            if (formState[key]?.[0]?.value) {
                requestBody[key] = formState[key].map(elem => elem.value);
            }
            else
                requestBody[key] = formState[key]
        })
        const { result, response } = await userReq.createUser(requestBody);
        console.log(result);
        if (result.success) {
            setFormState(initialFormState);
            setAlertOptions({
                severity: "success",
                visible: true,
                body: "User created successfully"
            })
            setGlobalUser(result.data);
            navigate("/selectprofile");
        }
        else {
            setAlertOptions({
                severity: "failure",
                visible: "true",
                body: "There was an error, try again!"
            })
            setErrors({ visible: true, msgs: result.errors })
            window.scrollTo(0, 0);
        }
        setIsLoading(false);
    }

    function confirmSubmit(event) {
        event?.preventDefault();
        confirmModal("Click 'yes' to submit form", submitForm);
    }



    function setSelect(item, key) {
        setFormState(oldFormState => ({ ...oldFormState, [key]: item.value }));
    }

    function setDate(value, key) {
        setFormState(oldFormState => ({ ...oldFormState, [key]: value }));
    }

    function setMultiSelect(item, key) {
        setFormState(oldFormState => ({ ...oldFormState, [key]: item }));
    }

    useEffect(() => {
        if (sameNumber) {
            setFormState(oldFormState => ({ ...oldFormState, "waPhone": formState.phone }));
        }
    }, [sameNumber])



    return (
        <div className="min-h-full flex flex-col gap-4 items-center justify-center p-2">
            <div>
                <h2 className="block capitalize tracking-wide text-gray-700 text-3xl mb-4 underline underline-offset-8 font-semibold">Fill your Basic Details</h2>
                {
                    errors.visible && <div className="shadow-md rounded-lg py-2 px-6 mb-4 text-base bg-red-100 text-red-700">
                        <p className="text-2xl">Error!!</p>
                        <ul className="list-disc list-inside">
                            {
                                errors.msgs?.map(error => (
                                    <li key={error.param}>{error.msg}</li>
                                ))
                            }
                        </ul>
                    </div>
                }
                <form
                    className="flex gap-6 w-full max-w-xl flex-wrap items-center justify-between p-2 md:p-6 shadow-md rounded-md border-2 bg-slate-50"
                    onChange={onChange}
                    onSubmit={confirmSubmit}
                >

                    <InputBlock
                        label="First Name"
                        id="fName"
                        placeholder="Aman"
                        type="text"
                        labelStyle={labelStyle}
                        fieldStyle={fieldStyle}
                        value={formState.fName}
                    />


                    <InputBlock
                        label="Last Name"
                        id="lName"
                        placeholder="Jain"
                        type="text"
                        labelStyle={labelStyle}
                        fieldStyle={fieldStyle}
                        value={formState.lName}
                    />

                    <DateBlock
                        label="Date of birth"
                        id="dob"
                        labelStyle={labelStyle}
                        fieldStyle={fieldStyle}
                        value={formState.dob}
                        onChange={(value) => setDate(value, "dob")}

                    />

                    <InputBlock
                        label="Phone Number"
                        id="phone"
                        placeholder="1234567890"
                        type="text"
                        labelStyle={labelStyle}
                        fieldStyle={fieldStyle}
                        value={formState.phone}

                    />

                    <InputBlock
                        label="Whatsapp (Same as phone?)"
                        id="waPhone"
                        placeholder="1234567890"
                        type="text"
                        disabled={sameNumber}
                        labelStyle={labelStyle}
                        fieldStyle={fieldStyle}
                        value={formState.waPhone}

                    >
                        <input
                            type="checkbox"
                            className="ml-1"
                            checked={sameNumber}
                            onChange={() => setSameNumber(!sameNumber)}
                        />
                    </InputBlock>



                    <InputBlock
                        label="Email Id"
                        id="email"
                        placeholder="Aman@gmail.com"
                        type="email"
                        labelStyle={labelStyle}
                        fieldStyle={fieldStyle}
                        value={formState.email}

                    />


                    <RadioBlock
                        id="gender"
                        label="Gender"
                        options={
                            [
                                { value: "male", label: "Male" },
                                { value: "female", label: "Female" },
                                { value: "other", label: "Other" },
                            ]
                        }
                        labelStyle={labelStyle}
                        value={formState.gender}

                    />

                    <InputBlock
                        label="Permanent Address"
                        id="address"
                        placeholder="A-75 Durgapura, Jaipur"
                        type="text"
                        className="!w-full"
                        labelStyle={labelStyle}
                        fieldStyle={fieldStyle}
                        value={formState.address}
                    />


                    <RadioBlock
                        id="inJaipur"
                        label="Are you living in jaipur?"
                        options={
                            [
                                { value: "yes", label: "Yes" },
                                { value: "no", label: "no" },
                            ]
                        }
                        labelStyle={labelStyle}
                        value={formState.inJaipur}
                    />

                    {formState.inJaipur === "yes" ?

                        <SelectBlock
                            label="Residential Area in jaipur"
                            id="location"
                            options={locations}
                            placeholder="Select Area"
                            labelStyle={labelStyle}
                            fieldStyle={fieldStyle}

                            value={formState.location !== null ? { value: formState.location, label: formState.location } : null}
                            onChange={(value) => setSelect(value, "location")}

                        /> : null
                    }

                    {formState.inJaipur === "no" ?

                        <>
                            <InputBlock
                                label="Outside Jaipur Location"
                                id="location"
                                placeholder="Ajmer"
                                labelStyle={labelStyle}
                                fieldStyle={fieldStyle}
                                value={formState.location}
                            />
                            <RadioBlock
                                id="moveJaipur"
                                label="Ready to move to Jaipur?"
                                options={
                                    [
                                        { value: "Yes", label: "Yes" },
                                        { value: "No", label: "No" },
                                    ]
                                }
                                labelStyle={labelStyle}
                                value={formState.moveJaipur}
                            />
                        </>
                        : null
                    }

                    <RadioBlock
                        id="ownVehicle"
                        label="Do you have your own vehicle?"
                        options={
                            [
                                { value: "Yes", label: "Yes" },
                                { value: "No", label: "No" },
                            ]
                        }
                        labelStyle={labelStyle}
                        value={formState.ownVehicle}
                    />

                    <RadioBlock
                        id="intercity"
                        label="Are you ready to travel intercity?"
                        options={
                            [
                                { value: "Yes", label: "Yes" },
                                { value: "No", label: "No" },
                            ]
                        }
                        labelStyle={labelStyle}
                        value={formState.intercity}
                    />

                    <RadioBlock
                        id="overTime"
                        label="Ready to give extra time for work?"
                        options={
                            [
                                { value: "Yes", label: "Yes" },
                                { value: "No", label: " No" },
                            ]
                        }
                        labelStyle={labelStyle}
                        value={formState.overTime}
                    />

                    <SelectBlock
                        label="Maximum Education"
                        id="maxEducation"
                        placeholder="Select..."
                        options={
                            [
                                { value: "Under Graduate", label: "Under Graduate" },
                                { value: "Graduate", label: "Graduate" },
                                { value: "Post Graduate", label: "Post Graduate" },
                            ]
                        }
                        value={formState.maxEducation !== null ? { value: formState.maxEducation, label: formState.maxEducation } : null}
                        onChange={(value) => setSelect(value, "maxEducation")}
                        labelStyle={labelStyle}
                        fieldStyle={fieldStyle}

                    />

                    <MultiSelectBlock
                        label="Workable Locations(min. 3)"
                        id="workLocations"
                        options={locations}
                        placeholder="Select"
                        labelStyle={labelStyle}
                        fieldStyle={fieldStyle}

                        value={formState.workLocations}
                        onChange={(value) => setMultiSelect(value, "workLocations")}

                    />

                    <SelectBlock
                        label="Experience"
                        id="experience"
                        placeholder="Select..."
                        options={
                            [
                                { value: "Fresher", label: "Fresher" },
                                { value: "0-1", label: "0-1 Years" },
                                { value: "1-3", label: "1-3 Years" },
                                { value: "3-5", label: "3-5 Years" },
                                { value: ">5", label: "More than 5 years" },
                            ]
                        }
                        labelStyle={labelStyle}
                        fieldStyle={fieldStyle}

                        value={formState.experience !== null ? { value: formState.experience, label: formState.experience } : null}
                        onChange={(value) => setSelect(value, "experience")}

                    />

                    {formState.experience !== "Fresher" ?
                        <MultiSelectBlock
                            label="Industries Worked"
                            id="industries"
                            options={[
                                { value: "CA Firm", label: "CA Firm" },
                                { value: "textile", label: "Textile" },
                                { value: "jewellery", label: "Jewellery" },
                            ]}
                            placeholder="Select"
                            labelStyle={labelStyle}
                            fieldStyle={fieldStyle}

                            value={formState.industries}
                            onChange={(value) => setMultiSelect(value, "industries")}

                        /> : null
                    }


                    <SelectBlock
                        label="Joining Time"
                        id="joinTime"
                        placeholder="Select"
                        labelStyle={labelStyle}
                        fieldStyle={fieldStyle}
                        options={[
                            { value: "immediately", label: "Immediately" },
                            { value: "Within 7 days", label: "Within 7 days" },
                            { value: "Within 15 days", label: "Within 15 days" },
                        ]}
                        value={formState.joinTime !== null ? { value: formState.joinTime, label: formState.joinTime } : null}
                        onChange={(value) => setSelect(value, "joinTime")}

                    />
                    <div className="w-full flex">
                        <Button type="submit" className="w-full" bgcolor="bg-slate-800">Submit</Button>
                    </div>

                </form>
            </div>

        </div>
    )
}