import DataContext from "../../../Contexts/DataContext";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
export default function Result() {
    function printDocument() {
        const input = document.getElementById('download');
        if (!input) return;
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF("p", "mm", "a4");
                let pageWidth = pdf.internal.pageSize.getWidth();
                let pageHeight = pdf.internal.pageSize.getHeight();
                let imageWidth = canvas.width;
                let imageHeight = canvas.height;

                var ratio = imageWidth / imageHeight >= pageWidth / pageHeight ? pageWidth / imageWidth : pageHeight / imageHeight;
                pdf.addImage(imgData, 'JPEG', 0, 0, imageWidth * ratio, imageHeight * ratio);
                pdf.save("download.pdf");
            })
            ;
    }
    const order = ["Tally", "Excel", "GST", "TDS & Income Tax"];
    const { reportObj,globalUser } = DataContext();
    console.log(reportObj);
    return (
        reportObj ?
            <div className="p-4 flex flex-col items-center">
                <button className="self-end focus:outline-none text-white bg-slate-700 hover:bg-slate-800 focus:ring-4 focus:ring-slate-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2" onClick={printDocument} type="button">Download PDF</button>
                <h2 className="text-gray-700 my-4 text-4xl font-semibold">Evaluation Score</h2>

                <div id="download" className="w-full">
                    {
                        globalUser && <div className="font-semibold text-lg">
                            <div>
                                <span className="w-80 font-medium">First Name:</span>
                                <span>{globalUser.fName}</span>
                            </div>

                            <div>
                                <span className="w-80 font-medium">Last Name:</span>
                                <span>{globalUser.lName}</span>
                            </div>

                            <div>
                                <span className="w-80 font-medium">Gender:</span>
                                <span>{globalUser.gender}</span>
                            </div>

                            <div>
                                <span className="w-80 font-medium">Location:</span>
                                <span>{globalUser.location}</span>
                            </div>

                            <div>
                                <span className="w-80 font-medium">Maximum Education:</span>
                                <span>{globalUser.maxEducation}</span>
                            </div>

                            <div>
                                <span className="w-80 font-medium">Experience:</span>
                                <span>{globalUser.experience}</span>
                            </div>

                            <div>
                                <span className="w-80 font-medium">Joining Time:</span>
                                <span>{globalUser.joinTime}</span>
                            </div>


                        </div>
                    }
                    <table className="w-full text-lg border-2 text-left text-gray-500 shadow">

                        <thead className="text-xs text-gray-50 uppercase bg-gray-800 text-center">
                            <tr>
                                <th scope="col" className="py-3 px-6">
                                    Category
                                </th>
                                <th scope="col" className="py-3 px-6 text-left">
                                    Sub-Category
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Sub Score
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Accuracy
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Total Score
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                order.map(category => {
                                    let categoryScore = 0;
                                    let categoryTotal = 0;
                                    let categoryAttempted = 0;
                                    return (
                                        <tr className="bg-white border-b text-center">
                                            <td scope="row" className="py-4 px-6 text-gray-900 whitespace-nowrap font-semibold text-2xl">
                                                {category}
                                            </td>
                                            <td className="py-4 px-6">
                                                <ul className="space-y-4 list-disc list-inside text-left">
                                                    {
                                                        Object.keys(reportObj[category]).map(subCategory => {
                                                            return (
                                                                <li>
                                                                    {subCategory}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </td>
                                            <td className="py-4 px-6">
                                                <ul className="space-y-4 text-red-500">
                                                    {
                                                        Object.keys(reportObj[category]).map(subCategory => {
                                                            let score = parseInt(reportObj[category][subCategory].score);
                                                            categoryScore += score;
                                                            let attempted = parseInt(reportObj[category][subCategory].attempted);
                                                            categoryAttempted += attempted;
                                                            let total = reportObj[category][subCategory].total;
                                                            categoryTotal += total;

                                                            return (
                                                                <li className="">
                                                                    <div className="inline-flex flex-wrap gap-2 border-[1px] items-center justify-center border-gray-400 p-1">
                                                                        {
                                                                            function () {
                                                                                let draw = [];
                                                                                let index = 0;
                                                                                for (let i = 0; i < score; i++) {
                                                                                    draw[index++] = <span className="w-4 h-4 rounded-full bg-green-500"></span>
                                                                                }
                                                                                for (let i = 0; i < attempted - score; i++) {
                                                                                    draw[index++] = <span className="w-4 h-4 rounded-full bg-red-500"></span>
                                                                                }

                                                                                for (let i = index; i < total; i++) {
                                                                                    draw[index++] = <span className="w-4 h-4 rounded-full border-[1px] border-red-700"></span>
                                                                                }
                                                                                return draw;
                                                                            }()
                                                                        }
                                                                    </div>
                                                                </li>

                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </td>
                                            <td className="py-4 px-6 text-xl text-blue-500">{categoryAttempted ? parseInt((categoryScore / categoryAttempted) * 100) : 0}%</td>
                                            <td className="py-4 px-6 text-xl text-blue-500">{parseInt((categoryScore / categoryTotal) * 100)}%</td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                </div>
            </div> :

            <div className="flex items-center justify-center text-center text-3xl font-semibold p-4">
                No result exists!
            </div>
    )
}