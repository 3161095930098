import Card from "../Card/Card"
export default function SelectProfile() {
    return (
        <div className="min-h-screen flex flex-col gap-8 items-center p-4 bg-gray-50">
            <h2 className="font-bold text-center capitalize text-4xl">Which Profile Position you want to Apply?</h2>
            <div className="flex flex-wrap gap-6">
                <Card
                    title="Fresher Accountant"
                    description="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Soluta sint aspernatur saepe ut itaque facilis dolorum veniam, voluptatem sequi maxime nam. Velit animi blanditiis, dignissimos eligendi fugit illum quis quod." />
            </div>
        </div>
    )
}