import { Route, Routes, Redirect } from "react-router-dom";
import Layout from "./Layout/Layout";
import Home from "./Home/Home";
import NoPage from './NoPage/NoPage';
import SignUp from "./Forms/SignUp/SignUp";
import SelectProfile from "./SelectProfile/SelectProfile";
import Quiz from "../Pages/Quiz/Quiz";
import Result from "../Pages/Quiz/Result/Result";
import { DataProvider } from "../Contexts/DataContext";
// import UserCreatedSuccess from "../Pages/UserCreatedSuccess/UserCreatedSuccess";
// import SignIn from "../Pages/SignIn/SignIn";
// import { AuthProvider } from "../Contexts/AuthContext";
// import AuthContext from "../Contexts/AuthContext";
export default function App() {
  // const { isAuthorised } = AuthContext();
  return (
    // <AuthProvider>
    <DataProvider>

      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/selectprofile" element={<SelectProfile />} />

          <Route path="/signup">
            <Route index element={<SignUp />} />
            {/* <Route path="success" element={<UserCreatedSuccess />} /> */}
          </Route>

          <Route
          // path="/signin" element={<SignIn />}
          />
          <>
            <Route path="/quiz" element={<Quiz />} />
            <Route path="/result" element={<Result />} />
          </>
          <Route path="*" element={<NoPage />} />

        </Route>
      </Routes >
    </DataProvider>

    // </AuthProvider>
  )
}