import { Link } from "react-router-dom"
export default function Home() {
    return (
        <section className="bg-gray-50">
            <div
                className="mx-auto px-4 py-32 max-w-screen-xl flex min-h-screen items-center"
            >
                <div className="mx-auto max-w-xl text-center">
                    <h1 className="text-3xl font-extrabold sm:text-5xl">
                        Excel in Career!
                        <br />
                        <strong className="font-extrabold sm:block text-[#072050]">
                            Join FINGROWW.
                        </strong>
                    </h1>

                    <p className="mt-4 sm:text-xl sm:leading-relaxed">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt illo
                        tenetur fuga ducimus numquam ea!
                    </p>

                    <div className="mt-8 flex flex-wrap justify-center gap-4">
                        <Link
                            className="block w-full rounded bg-indigo-600 px-12 py-3 text-sm font-medium text-white shadow hover:bg-indigo-700 focus:outline-none focus:ring active:bg-indigo-500 sm:w-auto"
                            to="/signup"
                        >
                            Get Started
                        </Link>

                        <a
                            className="block w-full rounded px-12 py-3 text-sm font-medium text-indigo-600 shadow hover:text-indigo-700 focus:outline-none focus:ring active:text-indigo-500 sm:w-auto"
                            href="https://fingroww.in"
                        >
                            Learn More
                        </a>
                    </div>
                </div>
            </div>
        </section>

    )
}