import { useEffect } from "react";
import { createContext, useState } from "react";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const AlertContext = createContext(null);
const initialAlertState = {
    visible: false,
    body: "Hi, this is an alert",
    severity: "normal",
}
export function AlertProvider({ children }) {

    function confirmModal(message, onYes) {
        confirmAlert({
            title: "",
            message,
            buttons: [
                {
                    label: "Yes",
                    onClick: onYes

                },
                {
                    label: "No",
                    onClick: () => { }
                }
            ]
        });
    }
    const [alertOptions, setAlertOptions] = useState(initialAlertState);

    return (
        <AlertContext.Provider value={{ alertOptions, setAlertOptions, confirmModal }}>
            {children}
        </AlertContext.Provider>
    )
}

export default AlertContext;