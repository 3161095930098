import { useTimer } from 'react-timer-hook';
import { GiStopwatch as TimerIcon } from 'react-icons/gi';

export default function Timer({ onExpire, expiryTimestamp, className }) {
    let { seconds, minutes } = useTimer({ expiryTimestamp, onExpire });
    seconds = seconds + "";
    minutes = minutes + "";
    if (seconds.length === 1) {
        seconds = "0" + seconds;
    }
    if (minutes.length === 1) {
        minutes = "0" + minutes;
    }

    return (
        <div className={className}>
            <div className="font-semibold bg-white text-red-700 text-2xl px-2 border-2 select-none flex items-center justify-center">

                <TimerIcon className="mr-1" />
                <span>{minutes}</span>:<span>{seconds}</span>
            </div>
        </div>
    );
}