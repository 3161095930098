import randomize from "../../functions/randomize";

export default function QuestionBlock({ questionBlock, index, onChange }) {
    const { question, options, _id, answer } = questionBlock;
    return (
        <div className="flex flex-col gap-4 items-center justify-center">
            <p className="mb-2  border-black border-2 shadow-lg w-full text-center p-2 font-semibold">Q.{index + 1})  {question}</p>
            <ul className="list-inside list-[upper-roman] flex flex-wrap gap-4 items-center justify-center">
                {
                    options.map((option, i) => {
                        const isSelected = answer === option;
                        return (


                            <label key={_id + i} className={`w-full min-h-[4rem] md:min-w-[20rem] py-1 px-4 border-2 shadow-md relative hover:scale-105 ${!isSelected && "hover:bg-slate-300"} flex items-center ${isSelected && "bg-slate-900 text-white"}`}>

                                <li className="font-semibold">

                                    <span className=" font-normal relative left-1">{option}</span>

                                </li>

                                <input type="radio" name={_id} checked={isSelected}
                                    onChange={() => onChange(index, option)} className="mx-2 hidden" />
                            </label>

                        )

                    }
                    )
                }
            </ul>

        </div >
    )
}