import makeRequest from "./makeRequest";
import URL from './url';
const questionReq = {};
const headers = {
    "Content-type": "application/json; charset=UTF-8"
}

async function request(method, endpoint, body = {}) {
    const url = `${URL}/questions/${endpoint}`;
    let result = await makeRequest(method, url, headers, body);
    return result;
}


questionReq.getAllQuestions = async () => {
    let result = await request("GET", "");
    return result;
}

questionReq.submitPaper = async (body) => {
    let result = await request("POST","submit",body);
    return result;
}


export default questionReq;