export default function RadioBlock({ label, id, options, labelStyle, value, fieldStyle }) {
    const selectedValue = value;
    return (
        <div className={`flex flex-col gap-1 w-full md:w-max`}>
            <p className={`${labelStyle} !text-base`}>{label}</p>
            <div className="flex gap-8">
                {
                    options.map(option => {
                        let label = option.label;
                        let value = option.value;
                        return (
                            <div className={`flex gap-4 items-center flex-wrap w-max`}>
                                <label className={labelStyle}>
                                    {label}
                                    <input type="radio" name={id} checked={selectedValue === value} className="ml-4" value={value} />

                                </label>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}