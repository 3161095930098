import { createContext, useContext, useState } from "react";

const DataContext = createContext({});
export function DataProvider({ children }) {
    const [reportObj, setReportObj] = useState(null);
    const [globalUser, setGlobalUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    return (
        <DataContext.Provider value={{ reportObj, setReportObj, globalUser, setGlobalUser, setIsLoading, isLoading }}>
            {children}
        </DataContext.Provider>
    )
}

export default () => (useContext(DataContext));