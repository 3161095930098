import { useState, useEffect, useContext } from "react";
import questionReq from "../../network/questionRequest";
import QuestionBlock from "./QuestionBlock"
import Button from "../../components/Forms/SignUp/Button/Button";
import randomize from "../../functions/randomize";
import DataContext from "../../Contexts/DataContext";
import { useNavigate } from "react-router-dom";
import Timer from "../../components/Timer/Timer";
import AlertContext from "../../Contexts/AlertContext";

export default function Quiz() {

    const [timersId, setTimersId] = useState([]);
    const [fetched, setFetched] = useState(false);
    const [expiryTimestamp, setExpiryTimeStamp] = useState(null);
    const { confirmModal, setAlertOptions } = useContext(AlertContext);
    const [questions, setQuestions] = useState(null);
    const navigate = useNavigate();
    const { setReportObj, globalUser, setIsLoading } = DataContext();

    async function fetchAndSetQuestions() {
        setIsLoading(true);
        let { result } = await questionReq.getAllQuestions();
        if (!result?.success) console.log('Encounterd error while fetching the resource');
        result = result.data.map(obj => {
            randomize(obj.options);
            return {
                ...obj, answer: ""
            }
        })
        randomize(result);
        setQuestions(result);
        setFetched(true);
        setIsLoading(false);
    }

    function onChange(index, answer) {
        setQuestions(oldState => {
            let newState = [...oldState];
            newState[index].answer = answer;
            return newState;
        })
    }

    async function submitPaper() {
        setIsLoading(true);
        const requestBody = {};
        requestBody.answers = questions.map(questionBlock => {
            return {
                _id: questionBlock._id,
                answer: questionBlock.answer
            }
        })
        requestBody._id = globalUser?._id;
        requestBody.position = "fresher accountant"
        const { result } = await questionReq.submitPaper(requestBody);
        console.log(result);
        if (result?.success) {
            setReportObj(result.data.result);
            navigate("/result");
        }
        setIsLoading(false);


    }

    function confirmSubmit(event) {
        event?.preventDefault();
        confirmModal("Click 'yes' to submit!", submitPaper);
    }

    useEffect(() => {
        if (fetched) {
            let date = new Date();
            const after = 25 * 60;
            date.setSeconds(date.getSeconds() + after);
            setExpiryTimeStamp(date);
            let timers = [];
            timers.push(setTimeout(() => {
                setAlertOptions({
                    visible: true,
                    body: "50% time has been elasped",
                    severity: "warning",
                })
            }, 1000 * (after * 50 / 100)));

            timers.push(setTimeout(() => {
                setAlertOptions({
                    visible: true,
                    body: "75% time has been elasped",
                    severity: "warning",
                })
            }, 1000 * (after * 75 / 100)));

            timers.push(setTimeout(() => {
                setAlertOptions({
                    visible: true,
                    body: "90% time has been elasped",
                    severity: "warning",
                })
            }, 1000 * (after * 90 / 100)));
            setTimersId(timers);
        }
    }, [fetched]);


    useEffect(() => {
        fetchAndSetQuestions();
    }, []);

    useEffect(() => {
        return () => {
            for (let timer of timersId) {
                clearInterval(timer);
            }
        };
    }, [timersId]);

    return (
        <div className="p-4 flex items-center justify-center gap-4 flex-col">
            <h2 className="block capitalize tracking-wide text-gray-700 text-3xl mb-4 underline underline-offset-8 font-semibold">Quiz</h2>

            {
                questions?.length ?
                    <>
                        {expiryTimestamp && <Timer
                            className="fixed top-0 left-0 z-50"
                            onExpire={submitPaper}
                            expiryTimestamp={expiryTimestamp}
                        />
                        }
                        <div className="p-4 border-2 shadow-md flex flex-col gap-8 max-w-4xl">
                            {
                                questions.map((questionBlock, index) => {
                                    return (
                                        <QuestionBlock key={questionBlock._id} index={index} questionBlock={questionBlock} onChange={onChange} />
                                    )

                                })
                            }
                            <Button onClick={confirmSubmit}>Submit</Button>

                        </div>
                    </>

                    : <p>No Quiz exists!</p>
            }
        </div>

    )
}