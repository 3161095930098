import { Link, NavLink } from 'react-router-dom';
import logo from "./Logo.png";
// import AuthContext from '../../Contexts/AuthContext';

export default function Navbar() {
    // const { isAuthorised, signout } = AuthContext();
    return (
        <nav className="h-20 text-xl gap-4 px-4 w-full shadow-md fixed z-10 border-b-2 border-gray-100 flex justify-between text-slate-800 items-center  bg-white">

            <img src={logo} alt="Fingroww Logo" className='w-auto max-h-full' />
            <NavLink to='/' className=" font-extrabold text-2xl ml-auto select-none hover:animate-pulse bg-clip-text bg-gradient-to-r from-[#072050] to-[#37326C]  text-transparent"
            >FINGROWW</NavLink>
            {/* {
                !isAuthorised ?
                    <div className='flex gap-4 items-center justify-center'>
                        <Link to="/signin" type="button" className="text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 ">SignIn</Link>
                        <Link to="/signup" type="button" className="text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 ">SignUp</Link>
                    </div> :
                    <button type="button" onClick={signout} className="text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-red-500">SignOut</button>
            } */}

        </nav>
    )
}