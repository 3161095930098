import { MultiSelect } from "react-multi-select-component";
export default function MultiSelectBlock({ label, id, options, placeholder, labelStyle, fieldStyle, onChange, value }) {
    return (
        <div className='flex flex-col gap-1 flex-grow'>
            <label className={labelStyle} htmlFor={id}>{label}</label>
            <MultiSelect
                isCreatable
                value={value}
                onChange={onChange}
                options={options}
                labelledBy={placeholder}
                id={id}
                className={`${fieldStyle} + !p-0`}
            />
        </div>
    )
}