import Select from 'react-select';
export default function SelectBlock({ label, id, options, placeholder = "Select", fieldStyle, labelStyle,onChange,value }) {
    return (
        <div className='flex flex-col gap-1 flex-grow'>
            <label htmlFor={id} className={labelStyle}>{label}</label>
            <Select
                value={value}
                onChange={onChange}
                options={options}
                className={`${fieldStyle} + !p-0`}
                placeholder={placeholder}
            />
        </div>
    )
}