import makeRequest from "./makeRequest";
import URL from './url';
const userReq = {};
const headers = {
    "Content-type": "application/json; charset=UTF-8"
}

async function request(method, endpoint, body = {}) {
    const url = `${URL}/users/${endpoint}`;
    let result = await makeRequest(method, url, headers, body);
    return result;
}



userReq.createUser = async (user) => {
    let result = await request("POST", "", user);
    return result;
}

export default userReq;