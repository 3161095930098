import { Link } from "react-router-dom"
export default function Card({ title = "Title", description = "description...", imgSrc = "" }) {
    return (
        <div className="overflow-hidden rounded-lg border border-gray-100 shadow-md w-96">
            <img
                alt="Office"
                src="https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
                className="h-56 w-full object-cover"
            />

            <div className="p-4 sm:p-6">
                <a href="#">
                    <h3 className="text-lg font-medium text-gray-900">
                        {title}
                    </h3>
                </a>

                <p className="mt-2 text-sm leading-relaxed text-gray-500 line-clamp-3">
                    {description}
                </p>

                <Link to="/quiz"
                    href="#"
                    className="group mt-4 inline-flex items-center gap-1 text-sm font-medium text-blue-600"
                >
                    Apply

                    <span
                        aria-hidden="true"
                        className="block transition group-hover:translate-x-0.5"
                    >
                        &rarr;
                    </span>
                </Link>
            </div>
        </div>
    )
}