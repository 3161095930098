import DatePicker from "react-date-picker/dist/entry.nostyle";
import "./DatePicker.css";
import "./Calendar.css";
export default function DateBlock({ label, id, labelStyle, fieldStyle, onChange, value }) {
    return (
        <div className="flex flex-col gap-1 flex-grow">
            <label htmlFor={id} className={labelStyle}>{label}</label>
            <DatePicker id={id}
                className={fieldStyle}
                value={value}
                onChange={onChange}
            />
        </div>
    )
}